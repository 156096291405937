import React from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams
  } from "react-router-dom";
import axios from 'axios'
import $ from 'jquery'
import './results.css'
import TablePaginated from '../features/results/tablePaginated'

const API_URL = process.env.REACT_APP_API_SCHEME + '://' + process.env.REACT_APP_API_HOST + (process.env.REACT_APP_API_PORT == 80 ? '' : (':' + process.env.REACT_APP_API_PORT)) + process.env.REACT_APP_API_ENDPOINT

export default function Results(props) {
    let { quiz_id } = useParams();
    

    return (
        <div>{quiz_id}
        <div className="row mt-5">
    <div className="col-lg-12">
        <div className="card-box-shared">
            <div className="card-box-shared-title">
                <h3 className="widget-title">My Quiz Attempts</h3>
            </div>
            <div className="card-box-shared-body">
                <div className="statement-table purchase-table table-responsive mb-5">
                    <table className="table">
                        <thead>
                        <tr>
                            <th scope="col">Course Title</th>
                            <th scope="col">Questions</th>
                            <th scope="col">Total Marks</th>
                            <th scope="col">Earned Marks</th>
                            <th scope="col">Pass Marks</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">
                                    <div className="statement-info">
                                        <ul className="list-items">
                                            <li className="mb-1">
                                                <span className="badge bg-success text-white p-1">Passed</span>
                                                <span>January 22, 2019 10:50am</span>
                                            </li>
                                            <li className="mb-1">
                                                <a href="course-details.html" className="primary-color">Adobe After Effects CC: The Complete Motion Graphics Course</a>
                                            </li>
                                        </ul>
                                    </div>
                                </th>
                                <td>
                                    <div className="statement-info">
                                        <ul className="list-items">
                                            <li>12</li>
                                        </ul>
                                    </div>
                                </td>
                                <td>
                                    <div className="statement-info">
                                        <ul className="list-items">
                                            <li>22.00</li>
                                        </ul>
                                    </div>
                                </td>
                                <td>
                                    <div className="statement-info">
                                        <ul className="list-items">
                                            <li>5.00(60%)</li>
                                        </ul>
                                    </div>
                                </td>
                                <td>
                                    <div className="statement-info">
                                        <ul className="list-items">
                                            <li>20.25(75%)</li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">
                                    <div className="statement-info">
                                        <ul className="list-items">
                                            <li className="mb-1">
                                                <span className="badge bg-danger text-white p-1">Failed</span>
                                                <span>January 22, 2019 10:50am</span>
                                            </li>
                                            <li className="mb-1">
                                                <a href="course-details.html" className="primary-color">The Ultimate Text-To-Video Creation Course</a>
                                            </li>
                                        </ul>
                                    </div>
                                </th>
                                <td>
                                    <div className="statement-info">
                                        <ul className="list-items">
                                            <li>12</li>
                                        </ul>
                                    </div>
                                </td>
                                <td>
                                    <div className="statement-info">
                                        <ul className="list-items">
                                            <li>22.00</li>
                                        </ul>
                                    </div>
                                </td>
                                <td>
                                    <div className="statement-info">
                                        <ul className="list-items">
                                            <li>2.00(7%)</li>
                                        </ul>
                                    </div>
                                </td>
                                <td>
                                    <div className="statement-info">
                                        <ul className="list-items">
                                            <li>20.25(75%)</li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">
                                    <div className="statement-info">
                                        <ul className="list-items">
                                            <li className="mb-1">
                                                <span className="badge bg-success text-white p-1">Passed</span>
                                                <span>January 22, 2019 10:50am</span>
                                            </li>
                                            <li className="mb-1">
                                                <a href="course-details.html" className="primary-color">Complete Ethical Hacking Course: Zero to Hero</a>
                                            </li>
                                        </ul>
                                    </div>
                                </th>
                                <td>
                                    <div className="statement-info">
                                        <ul className="list-items">
                                            <li>12</li>
                                        </ul>
                                    </div>
                                </td>
                                <td>
                                    <div className="statement-info">
                                        <ul className="list-items">
                                            <li>22.00</li>
                                        </ul>
                                    </div>
                                </td>
                                <td>
                                    <div className="statement-info">
                                        <ul className="list-items">
                                            <li>7.00(50%)</li>
                                        </ul>
                                    </div>
                                </td>
                                <td>
                                    <div className="statement-info">
                                        <ul className="list-items">
                                            <li>20.25(75%)</li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">
                                    <div className="statement-info">
                                        <ul className="list-items">
                                            <li className="mb-1">
                                                <span className="badge bg-danger text-white p-1">Failed</span>
                                                <span>January 22, 2019 10:50am</span>
                                            </li>
                                            <li className="mb-1">
                                                <a href="course-details.html" className="primary-color">Javascript - From Beginner to Pro-Build real world JS apps</a>
                                            </li>
                                        </ul>
                                    </div>
                                </th>
                                <td>
                                    <div className="statement-info">
                                        <ul className="list-items">
                                            <li>12</li>
                                        </ul>
                                    </div>
                                </td>
                                <td>
                                    <div className="statement-info">
                                        <ul className="list-items">
                                            <li>22.00</li>
                                        </ul>
                                    </div>
                                </td>
                                <td>
                                    <div className="statement-info">
                                        <ul className="list-items">
                                            <li>2.00(7%)</li>
                                        </ul>
                                    </div>
                                </td>
                                <td>
                                    <div className="statement-info">
                                        <ul className="list-items">
                                            <li>20.25(75%)</li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">
                                    <div className="statement-info">
                                        <ul className="list-items">
                                            <li className="mb-1">
                                                <span className="badge bg-danger text-white p-1">Failed</span>
                                                <span>January 22, 2019 10:50am</span>
                                            </li>
                                            <li className="mb-1">
                                                <a href="course-details.html" className="primary-color">iOS 12 & Swift: The Complete Developer Course (Project base)</a>
                                            </li>
                                        </ul>
                                    </div>
                                </th>
                                <td>
                                    <div className="statement-info">
                                        <ul className="list-items">
                                            <li>12</li>
                                        </ul>
                                    </div>
                                </td>
                                <td>
                                    <div className="statement-info">
                                        <ul className="list-items">
                                            <li>22.00</li>
                                        </ul>
                                    </div>
                                </td>
                                <td>
                                    <div className="statement-info">
                                        <ul className="list-items">
                                            <li>2.00(7%)</li>
                                        </ul>
                                    </div>
                                </td>
                                <td>
                                    <div className="statement-info">
                                        <ul className="list-items">
                                            <li>20.25(75%)</li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
        </div>
    )
}
