import React from 'react'
import axios from 'axios'
import AuthHeader from '../../api/authHeader'
import {
    BrowserRouter as Router,
    Link,
    NavLink,
    Redirect
} from "react-router-dom";
import { useTable, usePagination } from 'react-table'
import './tablePaginated.css'
import $ from 'jquery'

const API_URL = process.env.REACT_APP_API_SCHEME + '://' + process.env.REACT_APP_API_HOST + (process.env.REACT_APP_API_PORT == 80 ? '' : (':' + process.env.REACT_APP_API_PORT)) + process.env.REACT_APP_API_ENDPOINT

function ClientsTable({
    columns,
    data,
    fetchData,
    pageCount: controlledPageCount,
}) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: 100 },
            manualPagination: true,
            pageCount: -1,
        },
        usePagination
    )

    const exportXLS = (quiz_uuid) => {
        $('.preloader').show()
        axios.get(API_URL + '/quizzes/' + quiz_uuid + '/download', { headers: AuthHeader() })
            .then((response) => {
                switch (response.status) {
                    case 201:
                        window.open(API_URL + '/quizzes/' + quiz_uuid + '/results/export/' + response.data.data.key, '_blank');
                        break
                    case 204:
                        alert('Não existem resultados disponíveis.')
                        break
                }
                $('.preloader').delay('500').fadeOut(1000);
            }
            )
            .catch((error) => {
                alert('Link não disponível')
            })

    }

    React.useEffect(() => {
        $('.preloader').show();
        fetchData({ pageIndex, pageSize })
    }, [fetchData, pageIndex, pageSize])

    return (
        <div>
            <div class="card-box-shared">
                <div class="card-box-shared-title">
                    <h3 class="widget-title">Relatórios</h3>
                </div>
                <div className="card-box-shared-body">
                    <div className="statement-table purchase-table table-responsive mb-5">
                        <table className="table" {...getTableProps()}>
                            <thead>
                                {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(column => {
                                            if (column.id == 'download') {
                                                return
                                            }
                                            let size = column.id == 'status' || column.id == 'uuid' ? '100px' : ''
                                            return (
                                                <th style={{ width: size, }} className="" scope="col" {...column.getHeaderProps()} >
                                                    {column.render('Header')}
                                                </th>
                                            )

                                        }
                                        )}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {page.map((row, i) => {
                                    prepareRow(row)
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map(cell => {
                                                if (cell.column.id == 'uuid') {
                                                    return (
                                                        <td {...cell.getCellProps()}>
                                                            <div style={{
                                                                float: "right",
                                                            }} className="statement-info">
                                                                <div class="edit-action">
                                                                    <ul class="edit-list">
                                                                        <li><button style={{ width: "100px" }} onClick={() => exportXLS(cell.value)} class="theme-btn edit-btn"><i class="la la-edit mr-1 font-size-16"></i>Relatório</button></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    )
                                                }
                                                if (cell.column.id == 'status') {
                                                    switch (cell.value) {
                                                        case 'published':
                                                            return (
                                                                <td {...cell.getCellProps()}>
                                                                    <div className="statement-info">
                                                                        <span class="badge bg-success text-white p-1">Ativo</span>
                                                                    </div>
                                                                </td>
                                                            )
                                                        case 'in_testing':
                                                            return (
                                                                <td {...cell.getCellProps()}>
                                                                    <div className="statement-info">
                                                                        <span class="badge bg-info text-white p-1">Em teste</span>
                                                                    </div>
                                                                </td>
                                                            )
                                                        case 'unpublished':
                                                            return (
                                                                <td {...cell.getCellProps()}>
                                                                    <div className="statement-info">
                                                                        <span class="badge bg-danger text-white p-1">Não publicado</span>
                                                                    </div>
                                                                </td>
                                                            )

                                                    }
                                                }
                                                if (cell.column.id == 'download') {
                                                    return <div></div>
                                                }

                                                return (
                                                    <td {...cell.getCellProps()}>
                                                        <div className="statement-info">
                                                            {cell.render('Cell')}
                                                        </div>
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className="page-navigation-wrap mt-4 mb-4">
                        <div className="page-navigation-inner d-inline-block">
                            <div className="page-navigation">
                                <button type="button" className="btn page-go page-prev" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                    <i className="la la-arrow-left icon-table"></i>
                                </button>
                                <ul className="page-navigation-nav">
                                    <li><span className>Página <strong>{pageIndex + 1}</strong> </span></li>
                                </ul>
                                <button type="button" className="btn page-go page-next" onClick={() => nextPage()} disabled={!canNextPage}>
                                    <i className="la la-arrow-right icon-table"></i>
                                </button>
                                <div className="page-size">
                                    <label>Itens por página: </label>
                                    <div className="select-input sort-ordering user-form-short">
                                        <select className="sort-ordering-select-no-search" value={pageSize} onChange={e => { setPageSize(Number(e.target.value)) }} >
                                            {[10, 20, 50, 100].map(pageSize => (
                                                <option key={pageSize} value={pageSize}>
                                                    {pageSize}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClientsTable
