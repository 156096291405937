import React from 'react'
import AuthService from '../api/authService'
import './header.css'

const userInfo = JSON.parse(localStorage.getItem('user_info'))

export default props => {
    return (
        <>
            <header className="header-menu-area dashboard-header">
                <div className="header-menu-content dashboard-menu-content">
                    <div className="container-fluid">
                        <div className="main-menu-content">
                            <div className="row align-items-center">
                                <div className="col-lg-2">
                                    <div className="logo-box">
                                        <a href="index.html" className="logo"><img src="https://www.einstein.br/Style%20Library/components/newHome/img/Group%207004.png" alt="logo" /></a>
                                        <div className="menu-toggler">
                                            <i className="la la-bars"></i>
                                            <i className="la la-times"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-10">
                                    <div className="menu-wrapper">
                                        <div className="logo-right-button d-flex align-items-center">
                                            <div className="header-action-button d-flex align-items-center">
                                                <div className="user-action-wrap">
                                                    <div className="notification-item user-action-item">
                                                        <div className="dropdown">
                                                            <button className="notification-btn icon-color dropdown-toggle" type="button" id="userDropdownMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <i className="la la-gear"></i>
                                                            </button>
                                                            <div className="dropdown-menu" aria-labelledby="userDropdownMenu">
                                                                <div className="mess-dropdown">
                                                                    <div className="mess__title d-flex align-items-center">
                                                                        <div className="content">
                                                                            <h4 className="widget-title font-size-16">
                                                                                <a href="#" className="text-white">{userInfo.name}</a></h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mess__body">
                                                                        <ul className="list-items">
                                                                            <li className="mb-0">
                                                                                <a href="/logout" className="d-block" onClick={()=>AuthService.logout()}>
                                                                                    <i className="la la-power-off"></i> Logout
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="user-nav-container">
                                            <div className="humburger-menu">
                                                <div className="humburger-menu-lines side-menu-close"></div>
                                            </div>
                                            <div className="section-tab section-tab-2">
                                                <ul className="nav nav-tabs" role="tablist">
                                                    <li role="presentation">
                                                        <a href="#notification-home" role="tab" data-toggle="tab" className="active" aria-selected="true">
                                                            Notifications
                                            </a>
                                                    </li>
                                                    <li role="presentation">
                                                        <a href="#message-home" role="tab" data-toggle="tab" aria-selected="false">
                                                            Messages
                                            </a>
                                                    </li>
                                                    <li role="presentation">
                                                        <a href="#account-home" role="tab" data-toggle="tab" aria-selected="false">
                                                            Account
                                            </a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="user-panel-content">
                                                <div className="tab-content">
                                                    <div className="tab-pane fade active show" id="notification-home" role="tabpanel">
                                                        <div className="user-sidebar-item">
                                                            <div className="mess-dropdown">
                                                                <div className="mess__body">
                                                                    <a href="dashboard.html" className="d-block">
                                                                        <div className="mess__item">
                                                                            <div className="icon-element bg-color-1 text-white">
                                                                                <i className="la la-bolt"></i>
                                                                            </div>
                                                                            <div className="content">
                                                                                <span className="time">1 hour ago</span>
                                                                                <p className="text">Your Resume Updated!</p>
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                    <a href="dashboard.html" className="d-block">
                                                                        <div className="mess__item">
                                                                            <div className="icon-element bg-color-2 text-white">
                                                                                <i className="la la-lock"></i>
                                                                            </div>
                                                                            <div className="content">
                                                                                <span className="time">November 12, 2019</span>
                                                                                <p className="text">You changed password</p>
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                    <a href="dashboard.html" className="d-block">
                                                                        <div className="mess__item">
                                                                            <div className="icon-element bg-color-3 text-white">
                                                                                <i className="la la-check-circle"></i>
                                                                            </div>
                                                                            <div className="content">
                                                                                <span className="time">October 6, 2019</span>
                                                                                <p className="text">You applied for a job <span className="color-text">Front-end Developer</span></p>
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                    <a href="dashboard.html" className="d-block">
                                                                        <div className="mess__item">
                                                                            <div className="icon-element bg-color-4 text-white">
                                                                                <i className="la la-user"></i>
                                                                            </div>
                                                                            <div className="content">
                                                                                <span className="time">Jun 12, 2019</span>
                                                                                <p className="text">Your account has been created successfully</p>
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                    <a href="dashboard.html" className="d-block">
                                                                        <div className="mess__item">
                                                                            <div className="icon-element bg-color-5 text-white">
                                                                                <i className="la la-download"></i>
                                                                            </div>
                                                                            <div className="content">
                                                                                <span className="time">May 12, 2019</span>
                                                                                <p className="text">Someone downloaded resume</p>
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                                <div className="btn-box p-2 text-center">
                                                                    <a href="dashboard.html">Show All Notifications</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="message-home" role="tabpanel">
                                                        <div className="user-sidebar-item">
                                                            <div className="mess-dropdown">
                                                                <div className="mess__body">
                                                                    <a href="dashboard-message.html" className="d-block">
                                                                        <div className="mess__item">
                                                                            <div className="avatar dot-status">
                                                                                <img src="static/images/team7.jpg" alt="Team img" />
                                                                            </div>
                                                                            <div className="content">
                                                                                <h4 className="widget-title">Michelle Moreno</h4>
                                                                                <p className="text">Thanks for reaching out. I'm quite busy right now on many</p>
                                                                                <span className="time">5 min ago</span>
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                    <a href="dashboard-message.html" className="d-block">
                                                                        <div className="mess__item">
                                                                            <div className="avatar dot-status online-status">
                                                                                <img src="static/images/team8.jpg" alt="Team img" />
                                                                            </div>
                                                                            <div className="content">
                                                                                <h4 className="widget-title">Alex Smith</h4>
                                                                                <p className="text">Thanks for reaching out. I'm quite busy right now on many</p>
                                                                                <span className="time">2 days ago</span>
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                    <a href="dashboard-message.html" className="d-block">
                                                                        <div className="mess__item">
                                                                            <div className="avatar dot-status">
                                                                                <img src="static/images/team9.jpg" alt="Team img" />
                                                                            </div>
                                                                            <div className="content">
                                                                                <h4 className="widget-title">Michelle Moreno</h4>
                                                                                <p className="text">Thanks for reaching out. I'm quite busy right now on many</p>
                                                                                <span className="time">5 min ago</span>
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                    <a href="dashboard-message.html" className="d-block">
                                                                        <div className="mess__item">
                                                                            <div className="avatar dot-status online-status">
                                                                                <img src="static/images/team7.jpg" alt="Team img" />
                                                                            </div>
                                                                            <div className="content">
                                                                                <h4 className="widget-title">Alex Smith</h4>
                                                                                <p className="text">Thanks for reaching out. I'm quite busy right now on many</p>
                                                                                <span className="time">2 days ago</span>
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                    <a href="dashboard-message.html" className="d-block">
                                                                        <div className="mess__item">
                                                                            <div className="avatar dot-status">
                                                                                <img src="static/images/team8.jpg" alt="Team img" />
                                                                            </div>
                                                                            <div className="content">
                                                                                <h4 className="widget-title">Alex Smith</h4>
                                                                                <p className="text">Thanks for reaching out. I'm quite busy right now on many</p>
                                                                                <span className="time">2 days ago</span>
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                                <div className="btn-box p-2 text-center">
                                                                    <a href="dashboard-message.html">Show All Message</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="account-home" role="tabpanel">
                                                        <div className="user-sidebar-item user-action-item">
                                                            <div className="mess-dropdown">
                                                                <div className="mess__title d-flex align-items-center">
                                                                    <div className="image">
                                                                        <a href="#">
                                                                            <img src="static/images/team7.jpg" alt="John Doe" />
                                                                        </a>
                                                                    </div>
                                                                    <div className="content">
                                                                        <h4 className="widget-title font-size-16">
                                                                            <a href="#" className="text-white">
                                                                                Alex Smith
                                                                </a>
                                                                        </h4>
                                                                        <span className="email">alexsmith@example.com</span>
                                                                    </div>
                                                                </div>
                                                                <div className="mess__body">
                                                                    <ul className="list-items">
                                                                        <li className="mb-0">
                                                                            <a href="my-courses.html" className="d-block">
                                                                                <i className="la la-file-video-o"></i> My courses
                                                                </a>
                                                                        </li>
                                                                        <li className="mb-0">
                                                                            <a href="shopping-cart.html" className="d-block">
                                                                                <i className="la la-shopping-cart"></i> My cart
                                                                </a>
                                                                        </li>
                                                                        <li className="mb-0">
                                                                            <a href="my-courses.html" className="d-block">
                                                                                <i className="la la-bookmark"></i> My wishlist
                                                                </a>
                                                                        </li>
                                                                        <li className="mb-0">
                                                                            <div className="section-block mt-2 mb-2"></div>
                                                                        </li>
                                                                        <li className="mb-0">
                                                                            <a href="dashboard.html" className="d-block">
                                                                                <span><i className="la la-bell"></i> Notifications</span>
                                                                                <span className="badge bg-info text-white ml-2 p-1">9+</span>
                                                                            </a>
                                                                        </li>
                                                                        <li className="mb-0">
                                                                            <a href="dashboard-message.html" className="d-block">
                                                                                <span><i className="la la-envelope"></i> Messages</span>
                                                                                <span className="badge bg-info text-white ml-2 p-1">12+</span>
                                                                            </a>
                                                                        </li>
                                                                        <li className="mb-0">
                                                                            <a href="dashboard-settings.html" className="d-block">
                                                                                <i className="la la-gear"></i> Settings
                                                                </a>
                                                                        </li>
                                                                        <li className="mb-0">
                                                                            <a href="dashboard-purchase-history.html" className="d-block">
                                                                                <i className="la la-cart-plus"></i> Purchase history
                                                                </a>
                                                                        </li>
                                                                        <li className="mb-0">
                                                                            <div className="section-block mt-2 mb-2"></div>
                                                                        </li>
                                                                        <li className="mb-0">
                                                                            <a href="student-detail.html" className="d-block">
                                                                                <i className="la la-user"></i> Public Profile
                                                                </a>
                                                                        </li>
                                                                        <li className="mb-0">
                                                                            <a href="dashboard-settings.html" className="d-block">
                                                                                <i className="la la-edit"></i> Edit Profile
                                                                </a>
                                                                        </li>
                                                                        <li className="mb-0">
                                                                            <div className="section-block mt-2 mb-2"></div>
                                                                        </li>
                                                                        <li className="mb-0">
                                                                            <a href="#" className="d-block">
                                                                                <i className="la la-question"></i> Help
                                                                </a>
                                                                        </li>
                                                                        <li className="mb-0">
                                                                            <a href="index.html" className="d-block">
                                                                                <i className="la la-power-off"></i> Logout
                                                                </a>
                                                                        </li>
                                                                        <li className="mb-0">
                                                                            <div className="section-block mt-2 mb-2"></div>
                                                                        </li>
                                                                        <li>
                                                                            <div className="business-content">
                                                                                <a href="#">
                                                                                    <span className="widget-title font-size-18 d-block">Try Aduca for Business</span>
                                                                                    <span className="line-height-24 d-block primary-color-3">Bring learning to your company</span>
                                                                                </a>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}