import jwt_decode from "jwt-decode"
import AuthService from './authService'

export default function tokenValidation() {
    if (localStorage.getItem('access_token')) {
        const accessToken = JSON.parse(localStorage.getItem('access_token'))
        const userToken = jwt_decode(accessToken)
        const actualTimestamp = Math.floor(Date.now() / 1000)

        if (actualTimestamp <= userToken.exp) {
            return true
        } else {
            AuthService.logout()
            return false
        }
    }

    return false    
}
