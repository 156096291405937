import React, { Component } from 'react'
import axios from 'axios'
import './login.css'
import Message from '../features/login/message'
import AuthService from '../api/authService'
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import $ from 'jquery'
import ReCAPTCHA from 'react-google-recaptcha'

const URL = process.env.REACT_APP_API_SCHEME + '://' + process.env.REACT_APP_API_HOST + (process.env.REACT_APP_API_PORT == 80 ? '' : (':' + process.env.REACT_APP_API_PORT)) + process.env.REACT_APP_API_ENDPOINT

const required = value => {
    if (!value) {
        return (
            <div className="validation-msg">
                Campo obrigatório
            </div>
        );
    }
};

const email = value => {
    if (!isEmail(value)) {
        return (
            <div className="validation-msg" role="alert">
                Digite um e-mail válido.
            </div>
        );
    }
};

export default class Login extends Component {

    constructor(props) {
        super(props)

        this.state = {
            userLogin: null,
            userPassword: null,
            msgType: null,
            loading: true,
            recaptchaValidation: false
        }

        this.handleChangeUserLogin = this.handleChangeUserLogin.bind(this)
        this.handleChangeUserPassword = this.handleChangeUserPassword.bind(this)
        this.validateUser = this.validateUser.bind(this)
        this.handleLogin = this.handleLogin.bind(this)
        this.validateRecaptcha = this.validateRecaptcha.bind(this)

    }

    handleChangeUserLogin(e) {
        this.setState({
            ...this.state,
            userLogin: e.target.value
        })
    }

    handleChangeUserPassword(e) {
        this.setState({
            ...this.state,
            userPassword: e.target.value
        })
    }

    validateUser() {
        $('.preloader').show();
        AuthService.login(this.state.userLogin, this.state.userPassword).then(
            (response) => {
                if (response.status == 200) {
                    this.setState({
                        ...this.state,
                        msgType: 'login successful'
                    })
                    window.location.href = "/";
                }
            },
            error => {
                switch (error.response.status) {
                    case 401:
                        this.setState({
                            ...this.state,
                            msgType: 'login incorrect'
                        })
                        $('.preloader').delay('500').fadeOut(1000);
                        break

                    default:
                        this.setState({
                            ...this.state,
                            msgType: 'login incorrect'
                        })
                        $('.preloader').delay('500').fadeOut(1000);
                        break
                }
            }
        );
    }

    handleLogin(e) {
        e.preventDefault();
        if (this.state.recaptchaValidation) {
            this.form.validateAll();

            if (this.checkBtn.context._errors.length === 0) {
                this.validateUser()
            }
        } else {
            this.setState({
                ...this.state,
                msgType: 'recaptcha-false'
            })
        }
    }

    validateRecaptcha(value) {
        if (value && value != undefined && value != '') {
            return this.setState({
                ...this.state,
                recaptchaValidation: true,
                msgType: false
            })
        } else {
            return this.setState({
                ...this.state,
                recaptchaValidation: false,
                msgType: 'recaptcha-false'
            })
        }
    }

    componentDidMount() {
        window.history.replaceState(null, 'Login', '/login')
        $('.preloader').delay('500').fadeOut(1000);
    }

    render() {
        return (
            <div>
                <section className="login-area section--padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5 mx-auto">
                                <div className="card-box-shared">
                                    <div className="card-box-shared-title text-center">
                                        <h3 className="widget-title font-size-25">Realizar login na conta</h3>
                                    </div>
                                    <div className="card-box-shared-body">
                                        <div className="contact-form-action">
                                            <Form
                                                onSubmit={this.handleLogin}
                                                ref={c => { this.form = c; }}
                                            >
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <Message msgType={this.state.msgType} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="input-box">
                                                            <label className="label-text">E-mail<span className="primary-color-2 ml-1">*</span></label>
                                                            <div className="form-group">
                                                                <Input
                                                                    type="email"
                                                                    className="form-control"
                                                                    name="userLogin"
                                                                    value={this.state.userLogin}
                                                                    placeholder="Digite seu e-mail"
                                                                    onChange={this.handleChangeUserLogin}
                                                                    validations={[required, email]}
                                                                />
                                                                <span className="la la-envelope input-icon"></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="input-box">
                                                            <label className="label-text">Senha<span className="primary-color-2 ml-1">*</span></label>
                                                            <div className="form-group">
                                                                <Input
                                                                    type="password"
                                                                    className="form-control"
                                                                    name="userPassword"
                                                                    value={this.state.userPassword}
                                                                    placeholder="Digite sua senha"
                                                                    onChange={this.handleChangeUserPassword}
                                                                    validations={[required]}
                                                                />
                                                                <span className="la la-lock input-icon"></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 ">
                                                        <div className="btn-box">
                                                            <button className="btn-block theme-btn">Login</button>
                                                            <CheckButton
                                                                style={{ display: "none" }}
                                                                ref={c => {
                                                                    this.checkBtn = c;
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="recaptcha-area col-lg-12">
                                                        <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} onChange={this.validateRecaptcha} onExpired={this.validateRecaptcha} />
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }

}