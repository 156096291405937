import axios from "axios";


const API_URL = process.env.REACT_APP_API_SCHEME + '://' + process.env.REACT_APP_API_HOST + (process.env.REACT_APP_API_PORT == 80 ? '' : (':' + process.env.REACT_APP_API_PORT)) + process.env.REACT_APP_API_ENDPOINT

class AuthService {
  login(email, password) {
    return axios
      .post(API_URL + "/auth", {
        email,
        password
      })
      .then(response => {
        if (response.data.data) {
          localStorage.setItem("access_token", JSON.stringify(response.data.data.access_token));
          localStorage.setItem("user_info", JSON.stringify(response.data.data.user));
        }
        return response;
      });
  }

  logout() {
    localStorage.removeItem("access_token")
    localStorage.removeItem("user_info")
    window.location.href = "/";
  }

  registerUser(name, email, password) {
    return axios({
      method: 'post',
      url: API_URL + '/auth/register',
      headers: null,
      data: {
        name: name,
        email: email,
        password: password
      }
    })
      .then(response => {
        if (response.data.data.access_token) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response;
      })
      .catch((error) => {
        return error
      })
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));;
  }
}

export default new AuthService();
