import React from 'react'
import AuthService from '../api/authService'
import './sideBar.css'
import {
    BrowserRouter as Router,
    Link,
    NavLink
} from "react-router-dom";

export default props => {
    const onClickHandle = () => {

    }
    return (
        <div className="dashboard-sidebar">
            <div className="dashboard-nav-trigger">
                <div className="dashboard-nav-trigger-btn">
                    <i className="la la-bars"></i> Dashboard Nav
                </div>
            </div>
            <div className="dashboard-nav-container">
                <div className="humburger-menu">
                    <div className="humburger-menu-lines side-menu-close"></div>
                </div>
                <div className="side-menu-wrap">
                    <ul className="side-menu-ul">
                        <li className="sidenav__item"><NavLink activeClassName="active" to="/reports"><i className="la la-file-text-o"></i>Relatórios</NavLink></li>
                        <li className="sidenav__item"><a href="/logout" onClick={() => AuthService.logout()}><i class="la la-power-off"></i>Logout</a></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}