import React from 'react'

export default props => {
    return (
        <div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="copyright-content mt-0 pt-0 pb-4 border-top-0 text-center">
                        <div className="row">
                            <div className="col-lg-12">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}