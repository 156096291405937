import React, {useState, useEffect} from 'react'
import axios from 'axios'
import TokenValidation from '../api/tokenValidation'
import AuthHeader from '../api/authHeader'
import $ from 'jquery'
import './clients.css'
import TablePaginated from '../features/reports/tablePaginated'

const API_URL = process.env.REACT_APP_API_SCHEME + '://' + process.env.REACT_APP_API_HOST + (process.env.REACT_APP_API_PORT == 80 ? '' : (':' + process.env.REACT_APP_API_PORT)) + process.env.REACT_APP_API_ENDPOINT
export default function Client(props) {
    const columns = React.useMemo(
        () => [
            {
                Header: 'Nome',
                accessor: 'title',
            },
            {
                Header: 'Status',
                accessor: 'status',
            },
            {
                Header: '',
                accessor: 'uuid',
            },
            {
                Header: '',
                accessor:'download'
            }
        ],
        []
    )
    const [data, setData] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const fetchIdRef = React.useRef(0)

    const fetchData = React.useCallback(({ pageSize, pageIndex }) => {
        const fetchId = ++fetchIdRef.current
        setLoading(true)
        if(TokenValidation()){
            axios({
                method: 'get',
                url: API_URL + '/quizzes',
                headers: AuthHeader(),
                params: {
                    per_page: pageSize,
                    page: pageIndex + 1
                }
              })
                .then(response => {
                    const responseData = response.data.data
                    if (fetchId === fetchIdRef.current) {
                        setData(responseData)
                        setLoading(false)
                        $('.preloader').delay('250').fadeOut(500);
                    }
                })
                .catch((error) => {
                  return error
                })
        }
        
    }, [])
    return (
        <TablePaginated
            columns={columns}
            data={data}
            fetchData={fetchData}
            loading={loading}
        />
    )
}
