import React, { useState, useEffect } from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from 'react-router-dom'
import Preloader from './template/preloader'
import Header from './template/header'
import SideBar from './template/sideBar'
import Footer from './template/footer'
import DashboardPage from './pages/dashboard'
import Login from './pages/login'
import Reports from './pages/reports'
import Results from './pages/results'
import TokenValidation from './api/tokenValidation'

export default function App(props) {
    if (TokenValidation()) {
        return (
            <Router>
                <Preloader />
                <Header />
                <section className="dashboard-area">
                    <SideBar />
                    <div className="dashboard-content-wrap">
                        <div className="container-fluid">
                            <Switch>
                                <Route exact path="/">
                                    {/* Change for Dashboard after go horse */} 
                                    <Reports />
                                </Route>
                                <Route path="/dashboard">
                                    {/* Change for Dashboard after go horse */}
                                    <Reports />
                                </Route>
                                <Route path="/reports">
                                    <Reports />
                                </Route>
                                <Route path="/results/:quiz_id">
                                    <Results />
                                </Route>
                                <Route path="*">
                                    <Redirect to="/" />
                                </Route>
                            </Switch>
                            <Footer />
                        </div>
                    </div>
                </section>
            </Router>
        )
    } else {
        return (
            <div>
                <Preloader />
                <Login />
            </div>
        )
    }
}

